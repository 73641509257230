import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { getPoliciesAPI } from '../../services/routes.services';
import Loader from "../Common/PageLoader/LoadingSpinner";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import constants from '../../services/constants';
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
const PoliciesChartes = () => {
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [pdfData, setPdfData] = useState([]);
    const [popup, setPopup] = useState(null);
    const [loading, setLoading] = useState(true);

    const PdfPopupHandel = (item) => {
        if (item.fields.length === 1) {
            openPdfNewWindow(constants.Image_BASE_URL + item.fields[0].pdf, `${item.title}-${item.fields[0].language}.pdf`);
        } else {
            setPopup(item);
        }
    };
    
    const openPdfNewWindow = (pdf) => {
        window.open(pdf, '_blank');
    };
    const ClosePopupHandel = () => {
        setPopup(null);
    };

    const downloadPdf = (pdf, title) => {
        const link = document.createElement('a');
        link.href = pdf;
        link.download = title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        getPoliciesAPI()
            .then((res) => {
                const policies = res.data.policies;
                setPdfData(policies);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner + ' ' + Styles.noneMask}>
           {BannerImage &&   <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} alt="Banner" />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.policiesSec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.pdfContainer}>
                                <Row>
                                    {pdfData && pdfData.map((item) => (
                                        <Col md={3} key={item._id} className={Styles.pdfWidth}>
                                            <div className={Styles.pdfElem}>
                                                <button type="button" onClick={() => PdfPopupHandel(item)}>
                                                    <img src={require('../../assest/images/pdf-icon.png')} alt="PDF Icon" />
                                                    <h6>{item.title} </h6>
                                                </button>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {popup &&
                    <div className={Styles.popupModel}>
                        <div className={Styles.popupElem}>
                            <button type="button" onClick={ClosePopupHandel} className={Styles.ClosePopup}><IoMdClose /></button>
                            <h6>Select Policy Language & Download</h6>
                            <div className={Styles.LanguageButton}>
                                {popup.fields.map((pdfLanguage) => (
                                    <button 
                                        key={pdfLanguage._id} 
                                        type="button" 
                                        onClick={() => openPdfNewWindow(constants.Image_BASE_URL + pdfLanguage.pdf, `${popup.title}-${pdfLanguage.language}.pdf`)}
                                    >
                                        {pdfLanguage.language}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className={Styles.raiseSes}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.raiseContainer}>
                                <Row>
                                    <Col md={3}>
                                        <h3>Raise <span>Your</span> Concern</h3>
                                    </Col>
                                    <Col md={9}>
                                        <p>If you are aware of any ethical wrongdoings, have evidence of any malpractice taking place within our organization or want to bring to attention something that is affecting the integrity of our organization, we urge you to report it to the relevant authorities using the following email addresses.                                        </p>
                                        <div className={Styles.btnContainer}>
                                            <a href='mailto:ecounsellor@tataprojects.com' target="_blank" title='Chief ethics counsellor'><img src={require('../../assest/images/mail-icon-1.png')} /> Chief ethics counsellor</a>
                                            <a href='mailto:tpl-whistleblower@tataprojects.com' target="_blank" title='Chief ethics counsellor'><img src={require('../../assest/images/mail-icon-1.png')} /> Whistleblower</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PoliciesChartes;
