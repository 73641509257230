import React, { useState, useEffect, useRef } from 'react';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
const PersonalDetail = ({ nextStep,prevStep, handleChange, hasWorkedWithTata, formData,errors }) => {
  const dateInputRef = useRef(null);

  useEffect(() => {
    // Set the placeholder via JavaScript
    if (dateInputRef.current) {
      dateInputRef.current.placeholder = 'Select a date';
    }
  }, []);
  const [date, setDate] = useState("");
  const [isDate, setIsDate] = useState(false);
  const [isDateBirth, setIsBirth] = useState(false);
  return (
    <div>
      <h5>Personal Details</h5>
      
      {/* <div className={Styles.FormControl}>
          <div className={Styles.group}>
              <input
                type="text"
                name="mrf"
                placeholder='MRF'
                value={formData.mrf}
                onChange={handleChange('mrf', '')}
              />
              {errors.mrf && <span>{errors.mrf}</span>}
          </div>
          <div className={Styles.group}>
          <input
            type="text"
            name="preferredIndustry"
            placeholder='Preferred Industry'
            value={formData.preferredIndustry}
            onChange={handleChange('preferredIndustry', '')}
         />
          {errors.preferredIndustry && <span>{errors.preferredIndustry}</span>}
          </div>
      </div> */}
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          {/* <label htmlFor="dateApplied">Date Applied (YYYY-MM-DD):</label> */}
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.personalDetails.firstName}
            onChange={handleChange('firstName', 'personalDetails')}
           />
           {errors.firstName && <span>{errors.firstName}</span>}
          </div>
          <div className={Styles.group}>
          <input
            type="text"
            name="middleName"
            placeholder="Middle Name"
            value={formData.personalDetails.middleName}
            onChange={handleChange('middleName', 'personalDetails')}
          />
          {errors.middleName && <span>{errors.middleName}</span>}
          </div>
      </div>
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.personalDetails.lastName}
          onChange={handleChange('lastName', 'personalDetails')}
        />
        {errors.lastName && <span>{errors.lastName}</span>}
         </div>
        <div className={Styles.group}>
        <input
              type={isDateBirth || date ? "date" : "text"}
              onFocus={() => setIsBirth(true)}
              onBlur={() => setIsBirth(false)}
              placeholder={!isDateBirth ? "Select a Date of Birth" : ""}
              name="dateOfBirth"
              value={formData.personalDetails.dateOfBirth}
              onChange={handleChange('dateOfBirth', 'personalDetails')}
            />
            {errors.dateOfBirth && <span>{errors.dateOfBirth}</span>}
        </div>
      </div>
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          <input
            type="text"
            name="gender"
            placeholder="Gender"
            value={formData.personalDetails.gender}
            onChange={handleChange('gender', 'personalDetails')}
          />
          {errors.gender && <span>{errors.gender}</span>}
          </div>
          <div className={Styles.group}>
          <input
              type="text"
              name="nationality"
              placeholder="Nationality"
              value={formData.personalDetails.nationality}
              onChange={handleChange('nationality', 'personalDetails')}
            />
            {errors.nationality && <span>{errors.nationality}</span>}
          </div>
          </div>
          
      {hasWorkedWithTata && <button onClick={prevStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Previous</button>}
      <button type="button" onClick={nextStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Next</button>
    </div>
  );
};

export default PersonalDetail;
