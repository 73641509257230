import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
import { Link, useLocation } from "react-router-dom";
import Loader from "../Common/PageLoader/LoadingSpinner";
import { IoMdArrowBack } from "react-icons/io";
import axios from 'axios';
import constants from '../../services/constants';
import DOMPurify from 'dompurify';
import ContactDetail from '../../Components/Careers/contactFormDetail'
import EmployeeDetail from '../../Components/Careers/empFormDetail'
import EducationDetail from '../../Components/Careers/educationFormDetail'
import PersonalDetail from '../../Components/Careers/personalFormDetail'
import OthertDetail from '../../Components/Careers/otherFormDetail'
import SubmitDetail from '../../Components/Careers/submitFormDetail'
import ThankYou from "./thankYou";
// Function to sanitize input
const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };
const CareerForm=()=>{
    const location = useLocation();
    const { jobId } = location.state || {}; // Extract job from location state
    const [employeeDetailForm, setEmployeeDetailForm] = useState(true);
    const [personalDetailForm, setPersonalDetailForm] = useState(true);
    const [contactDetailForm, setContactDetailForm] = useState(false);
    const [educationDetailForm, setEducationDetailForm] = useState(false);
    const [otherDetailForm, setOtherDetailForm] = useState(false);
    const [submitDetailForm, setSubmitDetailForm] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); // Submission state
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);

    const [hasWorkedWithTata, setHasWorkedWithTata] = useState(true);


    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        'Employee Details',
        'Personal Details',
        'Contact Details',
        'Education Details',
        'Other info',
        'Submit'
    ];
    const [formData, setFormData] = useState({
    jobId:jobId,
    mrf: '',
    preferredIndustry: '',
    dateApplied: '',
    empDetail:{
      empId:'',
      location:'',
      duration:'',
      designation:'',
      entityName:'',
      reportingAuthority:'',
    },
    personalDetails: {
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      nationality: '',
    },
    contactDetails: {
      mobileNo: '',
      emailId: '',
      currentLocation: '',
    },
    educationDetails: [
      {
        relevantQualification: '',
        specialization: '',
        mode: '',
      },
    ],
    professionalCertifications: [
      {
        certificateName: '',
        validity: '',
      },
    ],
    experience: [{ expertise: '', yearsOfExperience: '' }],
    ctcDetails: {
      currentCTC: '',
      netSalary: '',
    },
    joiningTime: '',
    resume: null,
    consent:false
  });
  // Handles radio button change
  const handleRadioChange = (e) => {
    setHasWorkedWithTata(e.target.value === "yes");
    if(e.target.value === "no"){
      setStep(2)
      setCurrentStep(1)
    }
    if(e.target.value === "yes"){
      setStep(1)
      setCurrentStep(0)
    }
    
  };
   // Function to update experience data
  const updateExperience = (newExperienceArray) => {
    setFormData((prevState) => ({
      ...prevState,
      experience: newExperienceArray, // Update the experience array
    }));
  };
  const updateResume = (resumeFile) => {
    setFormData((prev) => ({ ...prev, resume: resumeFile }));
  };

  const updateConsent = (resumeFile) => {
    setFormData((prev) => ({ ...prev, consent: true }));
  };
  
  // Validation function
  const validateStep = () => {
    const newErrors = {};
    //alert(hasWorkedWithTata);
   // return false
    if(step===1){
        const { empId, location, duration, designation, entityName , reportingAuthority } = formData.empDetail;
        if (!empId) newErrors.empId = 'Employee Id is required';
        if (!location) newErrors.location = 'Location is required';
        if (!duration) newErrors.duration = 'Duration is required';
        if (!designation) newErrors.designation = 'Designation is required';
        if (!entityName) newErrors.entityName = 'Entity Name is required';
        if (!reportingAuthority) newErrors.reportingAuthority = 'Reporting Authority is required';
    }else{
      if (step === 2) {
        //if (!formData.mrf) newErrors.mrf = 'MRF is required';
        //if (!formData.preferredIndustry) newErrors.preferredIndustry = 'Preferred Industry is required';
        //if (!formData.dateApplied) newErrors.dateApplied = 'Date Applied is required';
        const { firstName, lastName, dateOfBirth, gender, nationality } = formData.personalDetails;
        if (!firstName) newErrors.firstName = 'First name is required';
        if (!lastName) newErrors.lastName = 'Last name is required';
        if (!dateOfBirth) newErrors.dateOfBirth = 'Date of birth is required';
        if (!gender) newErrors.gender = 'Gender is required';
        if (!nationality) newErrors.nationality = 'Nationality is required';
      }
      if (step === 3) {
          const { mobileNo, emailId, currentLocation } = formData.contactDetails;
           // Mobile number validation
          if (!mobileNo) {
               newErrors.mobileNo = 'Mobile number is required';
          } else {
              // Regex for validating a 10-digit mobile number
              //const mobileRegex = /^\d{10}$/;
              const mobileRegex = /^\+?[1-9]\d{1,14}$/;
              if (!mobileRegex.test(mobileNo)) {
              newErrors.mobileNo = 'Mobile number must be 10 digits';
              }
          }
          if (!emailId) {
              newErrors.emailId = 'Email is required';
            } else {
              // Email validation using regex
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(emailId)) {
                newErrors.emailId = 'Invalid email format';
              }
            }
          if (!currentLocation) newErrors.currentLocation = 'Current location is required';
      }
      if (step === 4) {
          const { relevantQualification, specialization, mode } = formData.educationDetails[0];
          if (!relevantQualification) newErrors.relevantQualification = 'Relevent Qualification is required';
          if (!specialization) newErrors.specialization = 'Specialization is required';
          if (!mode) newErrors.mode = 'Mode is required';
  
          const { certificateName, validity } = formData.professionalCertifications[0];
          if (!certificateName) newErrors.certificateName = 'Certification name is required';
          if (!validity) newErrors.validity = 'Validity is required';
          
      }
      if (step === 5) {
          const { expertise, yearsOfExperience} = formData.experience[0];
          if (!expertise) newErrors.expertise = 'Expertise field is required';
          if (!yearsOfExperience) newErrors.yearsOfExperience = 'Year of experience is required';
           
          const { currentCTC, netSalary} = formData.ctcDetails;
          if (!currentCTC) newErrors.currentCTC = 'CTC is required';
          if (!netSalary) newErrors.netSalary = 'Net salary is required';
          if (!formData.joiningTime) newErrors.joiningTime = 'Joining time is required';
      }
    }
    
    // Additional validations for other steps can be added similarly.

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle Next Step
  const nextStep = () => {
    //alert(step)
    if (validateStep()) {
      setStep((prev) => prev + 1);
      setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    }
  };

  // Handle Previous Step
  const prevStep = () => {
    setStep((prev) => prev - 1);
    setCurrentStep((prevStep) => Math.min(prevStep - 1, steps.length - 1));
  }
   

  // Handle Change for Nested Objects
  const handleChange = (input, section = null) => (e) => {
  const { name, value } = e.target;
  const sanitizedValue = sanitizeInput(value); // Sanitize the value

  setFormData((prevData) => {
    
    if (section) {
      // Update fields within a section
      return {
        ...prevData,
        [section]: {
          ...prevData[section],
          [name]: sanitizedValue,
        },
      };
    } else {
      // Update root-level fields
      return {
        ...prevData,
        [name]: sanitizedValue,
      };
    }
  });
  if (errors[name]) {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  }
};

  // Handle Change for Arrays
  const handleArrayChange = (index, section, name) => (e) => {
    const { value } = e.target;
    const updatedArray = formData[section].map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData((prevData) => ({
      ...prevData,
      [section]: updatedArray,
    }));
    if (errors[name]) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
    }
  };

  // Handle Form Submit
  const handleSubmit = async () => {
   // alert(hasWorkedWithTata);return false

   if (validateStep()) {
    
      const formDatas = new FormData();
      //return false;
      if (!formData.consent && step===4) {
        alert('You must agree to the consent statement before submitting the form.');
        return false;
      }
      try {
       
        
  
          formDatas.append("jobId", formData.jobId);
          formDatas.append("mrf", formData.mrf);
          formDatas.append("preferredIndustry", formData.preferredIndustry);
          formDatas.append("dateApplied", formData.dateApplied);
          formDatas.append("joiningTime", formData.joiningTime);
          formDatas.append("consent", formData.consent);
  
          // Manually append nested objects (like empDetails)
          formDatas.append("empDetail[empId]", formData.empDetail.empId);
          formDatas.append("empDetail[location]", formData.empDetail.location);
          formDatas.append("empDetail[duration]", formData.empDetail.duration);
          formDatas.append("empDetail[designation]", formData.empDetail.designation);
          formDatas.append("empDetail[entityName]", formData.empDetail.entityName);
          formDatas.append("empDetail[reportingAuthority]", formData.empDetail.reportingAuthority);
  
          //Manually append nested objects (like personalDetails)
          formDatas.append("personalDetails[firstName]", formData.personalDetails.firstName);
          formDatas.append("personalDetails[middleName]", formData.personalDetails.middleName);
          formDatas.append("personalDetails[lastName]", formData.personalDetails.lastName);
          formDatas.append("personalDetails[dateOfBirth]", formData.personalDetails.dateOfBirth);
          formDatas.append("personalDetails[gender]", formData.personalDetails.gender);
          formDatas.append("personalDetails[nationality]", formData.personalDetails.nationality);
  
          // Append arrays (like experience or professionalCertifications)
          formData.experience.forEach((exp, index) => {
            formDatas.append(`experience[${index}][expertise]`, exp.expertise);
            formDatas.append(`experience[${index}][yearsOfExperience]`, exp.yearsOfExperience);
          });
  
          formData.educationDetails.forEach((education, index) => {
            formDatas.append(`educationDetails[${index}][relevantQualification]`, education.relevantQualification);
            formDatas.append(`educationDetails[${index}][specialization]`, education.specialization);
            formDatas.append(`educationDetails[${index}][mode]`, education.mode);
          });
  
          formData.professionalCertifications.forEach((cert, index) => {
            formDatas.append(`professionalCertifications[${index}][certificateName]`, cert.certificateName);
            formDatas.append(`professionalCertifications[${index}][validity]`, cert.validity);
          });
  
          // Similarly, append contactDetails and ctcDetails
          formDatas.append("contactDetails[mobileNo]", formData.contactDetails.mobileNo);
          formDatas.append("contactDetails[emailId]", formData.contactDetails.emailId);
          formDatas.append("contactDetails[currentLocation]", formData.contactDetails.currentLocation);
  
          formDatas.append("ctcDetails[currentCTC]", formData.ctcDetails.currentCTC);
          formDatas.append("ctcDetails[netSalary]", formData.ctcDetails.netSalary);
  
        // Append the resume file if it exists
        if (formData.resume) {
          formDatas.append('resume', formData.resume);
        }
        //console.log(formData.resume)
       //console.log('before submit ===', formDatas)
        //return false;
        const response = await axios.post(`${ constants.API_BASE_URL}application`, formDatas);
        console.log('Form submitted successfully:', response.data);
        setIsSubmitted(true)
      } catch (error) {
        console.error('Error submitting form:', error);
      }
   }else{
    
    return false;
   }
    //console.log('first====',formData)
    
  };

  useEffect(()=>{
    //alert(step)
    // Render Form Steps
    setEmployeeDetailForm(false)
    setPersonalDetailForm(false);
    setContactDetailForm(false);
    setOtherDetailForm(false)
    setSubmitDetailForm(false)
    setEducationDetailForm(false)
    if(step===2){
        setEmployeeDetailForm(false)
        setPersonalDetailForm(true);
    }else if(step===3){
        setPersonalDetailForm(false);
        setContactDetailForm(true);
        
    }else if(step===4){
        setEducationDetailForm(true)
        setContactDetailForm(false) 
    }else if(step===5){
        setOtherDetailForm(true); 
        setEducationDetailForm(false)
        
    }else if(step===6){
      setOtherDetailForm(false); 
      setSubmitDetailForm(true)
    }
    else{
      //alert(hasWorkedWithTata)
        if(hasWorkedWithTata===true){
          setEmployeeDetailForm(true)
        }else{
          setPersonalDetailForm(true);
          setStep(2)
        }    
        
    }
  },[step])


    return(
        <div className={'CertificationPage ' + Styles.CareerPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                <img src={require('../../assest/images/career_bg.4564da8ff0b071690a09.jpg')} className={Styles.innerBannerimg} />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Working With Us</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.jobApplySec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.positionContainer +" " + Styles.ApplyFor}>
                              <h2>Candidate Profile</h2>
                              <>
                              <h4>Have you ever worked with any of the TATA Group Companies in any capacity?<span className={Styles.dots}>*</span></h4>
                                  <div className={Styles.radioBtn}>
                                  <label>Yes
                                    <input type="radio" name="workedWithTata" value="yes" onChange={handleRadioChange} checked={hasWorkedWithTata === true} />
                                    <span className={Styles.checkmark}></span>
                                  </label>
                                  <label>No
                                    <input type="radio" name="workedWithTata" value="no" onChange={handleRadioChange} checked={hasWorkedWithTata === false} />
                                    <span className={Styles.checkmark}></span>
                                  </label>
                                </div>
                              </>
                              <>
                                      <ul className={Styles.submitJobLavel}>
                                      {/* <li className={Styles.active}>Personal Details</li>
                                      <li>Contact Details</li>
                                      <li>Education Details</li>
                                      <li>Other info</li>
                                      <li>Submit</li> */}
                                      {steps.map((step, index) => (
                                      <li
                                      key={index}
                                      className={index === currentStep ? Styles.active : ''}
                                      >
                                      {step}
                                      </li>
                                      ))}
                                  </ul>
                                      </>
                            {hasWorkedWithTata && employeeDetailForm ? (
                                        <div>
                                          <EmployeeDetail handleChange={handleChange} formData={formData} errors={errors} nextStep={nextStep} handleSubmit={handleSubmit}/>
                                        </div>
                                      ): 
                                      ''
                                      }
                             {isSubmitted ? (
                                    // Show thank you message after submission
                                    <ThankYou />
                                ) : (                    
                                 <>
                               
                                    

                                 
                                  
                                
                                {personalDetailForm ?
                                    <div className={Styles.fromStyles}>
                                        <PersonalDetail nextStep={nextStep} prevStep={prevStep} hasWorkedWithTata={hasWorkedWithTata} handleChange={handleChange} formData={formData} errors={errors}/>
                                    </div> 
                                    : null
                                }
                             
                                {contactDetailForm ?
                                    <div className={Styles.fromStyles}>
                                        
                                        <ContactDetail errors={errors} nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} handleArrayChange={handleArrayChange} formData={formData}/>
                                    </div>
                                    : null
                                }
                                {educationDetailForm ?
                                    <div className={Styles.fromStyles}>
                                       
                                        <EducationDetail errors={errors} nextStep={nextStep}   prevStep={prevStep} handleChange={handleChange} handleArrayChange={handleArrayChange} formData={formData}/>
                                    </div>
                                    : null
                                }
                                {otherDetailForm ?
                                    <div className={Styles.fromStyles}>
                                        <OthertDetail errors={errors} nextStep={nextStep} prevStep={prevStep} updateResume={updateResume} experience={formData.experience} updateExperience={updateExperience} handleChange={handleChange} handleArrayChange={handleArrayChange} formData={formData}/>
                                    </div>
                                    : null
                                }
                                {submitDetailForm ?
                                    <div className={Styles.fromStyles}>
                                        <SubmitDetail errors={errors} prevStep={prevStep} updateConsent={updateConsent} handleChange={handleChange} handleArrayChange={handleArrayChange} formData={formData} handleSubmit={handleSubmit}/>
                                    </div>
                                    : null
                                }
                                </>)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CareerForm;