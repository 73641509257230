import React, { useState, useEffect } from "react";
import Styles from '../Style/Common.module.css';
import { getWhoWeAreAPI } from "../../services/routes.services";
import CountUp from 'react-countup';
const TQRank = ()=>{
    const [weAreData, setWeAreData] = useState(null);
    useEffect(()=>{
        getWhoWeAreAPI().then((res)=>{
            setWeAreData(res.data.whoweare[0]);
        });
    }, [])

    function formatNumber(num) {
        if (num >= 1000) {
          const k = Math.floor(num / 1000);
          return `${k}K+`;
        }
        return num.toString();
      }

    return(
        <div className={Styles.areRank}>
            {weAreData &&
                <ul>
                    {weAreData.fields.map((item)=>{
                        return(
                            <li key={item._id}>
                                <h2>{formatNumber(item.field1)}</h2>
                                <h6>{item.field2}</h6>
                            </li>
                        )
                    })}
            
                </ul>
            }  
        </div>
    )
}

export default TQRank;