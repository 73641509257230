import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import BtnStyle from '../../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeDetailAPI } from '../../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
import Warehouse from "./Warehousing";

const SafetyAudits = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [safetyData, setSafetyData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        getServicesTypeDetailAPI('safety-audits').then((res)=>{
            setSafetyData(res.data.servicedetails[0]);
            setLoading(false)
        }).catch(error =>{ 
            console.log(error)
            setLoading(false)
        })
    }, [])
    if (loading) {
        return <Loader />;
    }


    return(
        <div className={'CertificationPage ' + Styles.OrganicPage  + ' ' + Styles.CFTStyleSame}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
               {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.IBSOverview + " "  + Styles.AuditSec}>
                {safetyData.fields && safetyData.fields.length > 0 &&
                    <Container className={Styles.organicSpace}>
                        <Row>
                            <Col md={5}>
                                <figure>
                                 {safetyData.fields[0].image[0] && <img src={constants.Image_BASE_URL+""+safetyData.fields[0].image[0].fileName} />}
                                </figure>
                            </Col>
                            <Col md={7}>
                                <div className={Styles.content}>
                                    <h3>{safetyData.fields[0].title}</h3>
                                    {parse(`${safetyData.fields[0].description}`)}
                                </div>
                            </Col>
                        </Row>
                        <Row className={Styles.secSpace}>
                            <Col md={8}>
                                    {parse(`${safetyData.fields[0].description2}`)}
                                    <h5>{safetyData.fields[1].title}</h5>
                                    {parse(`${safetyData.fields[1].description}`)}
                            </Col>
                            <Col md={4}>
                                <figure>
                                   {safetyData.fields[1].image[0] && <img src={constants.Image_BASE_URL+""+safetyData.fields[1].image[0].fileName} />}
                                </figure>
                            </Col>
                            <Col md={12}>
                                <h5>{safetyData.fields[2].title}</h5>
                                {parse(`${safetyData.fields[2].description}`)}
                                <h5>{safetyData.fields[3].title}</h5>
                                {parse(`${safetyData.fields[3].description}`)}
                                <h5>{safetyData.fields[4].title}</h5>
                                {parse(`${safetyData.fields[4].description}`)}
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <Warehouse />
        </div>
    )
}

export default SafetyAudits;