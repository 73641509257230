import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';


const Improvement = (props)=>{
    const data = props.Improve;
    return(
        <div>
        <div className={'Improve ' + Styles.ImproveSec}>
            <Container>
                <Row>
                    <Col>
                        <div className={Styles.ImproveContainer}>
                            <p>{data.description}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className={Styles.CertificatSec}>
            <Container>
                <Row>
                    <Col>
                        <div className={Styles.SectorsHeadaing + ' ' + Styles.centerAlight}>
                            <h3>Certificates & Accreditations</h3> 
                        </div>
                        <ul>
                            <li>
                                <figure>
                                    <img src={require('../../assest/images/ios_9001.png')} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={require('../../assest/images/ios_17020.png')} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={require('../../assest/images/ios_14001.png')} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={require('../../assest/images/ios_17021.png')} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={require('../../assest/images/ios_45001.png')} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={require('../../assest/images/ios_17065.png')} />
                                </figure>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
    )
}

export default Improvement;