// src/config.js


const config = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    Image_BASE_URL: process.env.REACT_APP_IMAGE_BASE_URL,
    File_BASE_URL: process.env.REACT_APP_FILE_BASE_URL,
  };
  
  export default config;
  