import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import axios from "axios";
import Styles from '../Style/Common.module.css';

const geoUrl = "https://unpkg.com/world-atlas/countries-50m.json"; // Simplified TopoJSON URL

const Global = () => {
  const [locations, setLocations] = useState([]);
  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [hoveredGeography, setHoveredGeography] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("https://cqcert.olive.co.in/api/v1/maps")
      .then((response) => {
        setLocations(response.data.maps);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching map data:", error);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Function to calculate the centroid of a geometry
  const calculateCentroid = (coordinates) => {
    let x = 0;
    let y = 0;
    let n = 0;

    // Handle multi-polygon and single polygon cases
    coordinates.forEach(polygon => {
      polygon.forEach(point => {
        x += point[0];
        y += point[1];
        n++;
      });
    });

    return n > 0 ? [x / n, y / n] : [0, 0]; // Return average x and y for the centroid, default to [0,0] if no points
  };

  return (
    <div className={'sectors ' + Styles.SectorsSec +" " + Styles.GlobalSec}>
      <Container>
        <Row>
          <Col>
            <div className={Styles.SectorsHeadaing + ' ' + Styles.centerAlight}>
              <h3>Global Presence</h3> 
            </div>
            <div className={Styles.globalStyle}>
              <img src={require('../../assest/images/global_map.png')} />
              {/* <iframe src="https://www.google.com/maps/d/embed?mid=1eIEfKlDPY9e3iAMPQsRgWLF5EN-rcuU&amp;ehbc=2E312F" width="1040" height="480"></iframe> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Global;
