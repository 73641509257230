import React from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import Styles from '../Style/Common.module.css'
const ThankYou = () => {
  const navigate = useNavigate(); // Hook for navigation

  const navigateToJobList = () => {
    navigate('/careers#career'); // Adjust path as needed for your job listing page
  };

  return (
    <div className={Styles.thanksCenter}>
      <h2>Thank You!</h2>
      <p>Your application has been submitted successfully.</p>
      <button onClick={navigateToJobList}>Go to Job Listings</button>
    </div>
  );
};

export default ThankYou;
