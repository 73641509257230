import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link, useNavigate } from "react-router-dom";
import constants from '../../services/constants';
import 'react-tabs/style/react-tabs.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import parse from 'html-react-parser';
import { getSectorsAPI } from "../../services/routes.services";

const Sectors = (props)=>{
    const [sectorData, setSectorData] = useState([]);
    useEffect(()=>{
        getSectorsAPI().then((res)=>{
            setSectorData(res.data.sector);
        });
    }, [])
    const assuranceResponsive = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 0 },
          shouldResetAutoplay:true,
          items: 1,
        },
      };
      const adjustPath = (path) => {
       
        // Example function to adjust paths
        return path.replace('/project-services/', '/');
       
    };
    const navigate = useNavigate();
    const SectorDetail=(item)=>{
        navigate(`/sector/${item.link}`, {state:{Id:item._id, Title: item.title, Description: item.description}})
    }
    //console.log(sectorData)
    return(
        <div className={'sectors ' + Styles.SectorsSec}>
            <Container>
                <Row>
                    <Col>
                        <div className={Styles.SectorsHeadaing + ' ' + Styles.centerAlight}>
                            <h3>Sectors</h3>
                            <h5>We Operate</h5>
                        </div>
                        <div className={Styles.SectorsSlid}>
                            <Carousel autoPlaySpeed={2000} transitionDuration={500} responsive={assuranceResponsive} autoPlay={true} infinite={true} arrows={true} containerClass="assuranceOwl arrowStyle alignBtn">
                                {sectorData &&
                                    sectorData.map((item)=>{
                                        const adjustedLink = adjustPath(item.link);
                                        return(
                                            <div className={Styles.SectorsItemContainer} key={item._id}>
                                                <figure>
                                                  {item.image &&  <img src={ constants.Image_BASE_URL+""+item.image} alt={item.title} width='1235' height='388' />}
                                                </figure>
                                                <div className={Styles.SectorsItemElem}>
                                                    <div className={Styles.sectorTitle}>
                                                        <h4>
                                                            {item.title} 
                                                            {/* <span><Link to={`/${adjustedLink}`}  title={item.title} className={BtnStyle.roundBtnLg + ' ' + BtnStyle.RoundRed}><img src={require('../../assest/images/White_Arrow.png')} alt={item.title} width='12' height='12' /></Link></span> */}
                                                        </h4>
                                                    </div>
                                                    {/* <div className={Styles.sectorDes}><p>{parse(`${item.description}`)}</p></div>
                                                    <div className={Styles.sectorLink}>
                                                        <Link to={`/${adjustedLink}`}  title={item.title} className={BtnStyle.roundBtnLg + ' ' + BtnStyle.RoundRed}><img src={require('../../assest/images/White_Arrow.png')} alt={item.title} width='12' height='12' /></Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Sectors;