import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { getServicesTypeDetailAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
const DesignEngineering = ()=>{
     //math Path
     const location = useLocation();
     const { menu } = useSelector(state => state.header);
     const currentPageUrl = location.pathname;
     const currentMenuItem = MenuPath(menu, currentPageUrl);
     const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
     const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [IPMData, setIPMData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        getServicesTypeDetailAPI('design-and-engineering-reviews').then((res)=>{
            setIPMData(res.data.servicedetails[0]);
            setLoading(false)
        }).catch(error =>{ 
            console.log(error)
            setLoading(false)
        })
    }, [])
    console.log(IPMData)
    if (loading) {
        return <Loader />;
    }
    
    return(
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
            {BannerImage &&    <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.TQValue}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.TQvalueContainer}>
                                <Row>
                                    <Col md={8}>
                                        <div className={Styles.TQElem}>
                                            <h3>{IPMData.titlename}</h3>
                                            {parse(`${IPMData.description}`)}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={Styles.TQvalueImg}>
                                            <figure className={Styles.imgBgStyle}>
                                            {IPMData.image &&    <img src={constants.Image_BASE_URL+""+IPMData.image} />}
                                            </figure>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DesignEngineering;