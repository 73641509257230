import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeAPI } from '../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../services/constants';
import Loader from "../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import { getServiceOverVIewAPI } from '../../services/routes.services';
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
const TQAllServices = ()=>{
     //math Path
     const location = useLocation();
     const { menu } = useSelector(state => state.header);
     const currentPageUrl = location.pathname;
     const currentMenuItem = MenuPath(menu, currentPageUrl);
     const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
     const BannerTitle = currentMenuItem ? currentMenuItem.linkText : '';
     console.log(currentMenuItem)
    const [serviceData, setServicesData] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        getServiceOverVIewAPI().then((res)=>{
            setServicesData(res.data.servicedetails[0].fields);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [])
    if (loading) {
        return <Loader />;
    }
    console.log(serviceData)
    return(
        <div className={'CertificationPage ' + Styles.projectOverViewPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
               {BannerImage && <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.ovewViewBlock}>
                {serviceData &&
                    serviceData.map((item)=>{
                        return(
                            <div className={Styles.servicesSection} key={item._id}>
                                <img src={require('../../assest/images/services_mask.png')} className={Styles.servicesMask} />
                                <div className={Styles.servicesElem}>
                                    <div className={Styles.contentOrder}>
                                        <div className={Styles.content}>
                                            <h4>{item.title}</h4>
                                            {parse(`${item.description}`)}
                                            {item.link==='/Certification'?(<Link to="/certification" title={item.title} className={BtnStyle.roundBtnLg + ' ' + BtnStyle.RoundRed}><img alt='' width='12' height='12' src={require('../../assest/images/White_Arrow.png')} /></Link>):
                                          (  <Link to={item.link} title={item.title} className={BtnStyle.roundBtnLg + ' ' + BtnStyle.RoundRed}><img alt='' width='12' height='12' src={require('../../assest/images/White_Arrow.png')} /></Link>)}
                                        </div>
                                    </div>
                                    <div className={Styles.imgOrder}>
                                        <figure>
                                           {item.image[0] && <img src={constants.Image_BASE_URL+""+item.image[0].fileName} alt={item.title} width='498' height='422' />}
                                        </figure>
                                    </div>
                                </div>
                            </div>           
                        )
                    })
                }

            </div>
        </div>
    )
}

export default TQAllServices;