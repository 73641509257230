import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import BtnStyle from '../../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeDetailAPI, getWhyChooseusAPI } from '../../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Warehouse = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [bodyData, setBodyData] = useState([]);
    const [whyChooseus, setWhyChooseus] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true)
        Promise.all([
            getServicesTypeDetailAPI('warehousing'),
            getWhyChooseusAPI('warehousing'),
        ]).then(([houseRes, chooseRes])=>{
            setBodyData(houseRes.data.servicedetails[0]);
            setWhyChooseus(chooseRes.data.whychooseus[0]);
        }).catch(error =>{ 
            console.log(error)
        }).finally(()=>{
            setLoading(false)
        })
    }, [])
    console.log(bodyData)
    if (loading) {
        return <Loader />;
    }
    const assuranceResponsive = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 1200 },
          shouldResetAutoplay:true,
          items: 3,
        },
        desktop:{
            breakpoint: { max: 1200, min: 0 },
          shouldResetAutoplay:true,
          items: 2,
        }
    };

    return(
        <div className={'CertificationPage ' + Styles.OrganicPage  + ' ' + Styles.CFTStyleSame}>
            {bodyData.fields && bodyData.fields.length > 0 && (
                <>
            <div className={Styles.wareHouse}>
                <Container className={Styles.organicSpace}>
                    <Row>
                        <Col>
                            <h3>{bodyData.fields[0].title}</h3>
                            {parse(`${bodyData.fields[0].description}`)}
                            <figure>
                               {bodyData.fields[0].image[0] && <img src={constants.Image_BASE_URL+""+bodyData.fields[0].image[0].fileName} />}
                            </figure>
                            {parse(`${bodyData.fields[0].description2}`)}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.wareHouseSlid}>
                <Container className={Styles.organicSpace}>
                    <Row>
                        <Col>
                            <h3>Why Choose Us?</h3>
                        </Col>
                    </Row>
                </Container>
                <div className={Styles.houseContainer}>
                    <div className='w-100'>
                        <Carousel autoPlaySpeed={2000} transitionDuration={500} responsive={assuranceResponsive} autoPlay={true} infinite={true} arrows={false} containerClass={Styles.slideItemOwl}>
                            {whyChooseus.fields.map((item, index)=>{
                                return(
                                    <div className={Styles.wareHouseItemBlock} key={index}>
                                        <div className={Styles.wareHouseItem}>
                                            <figure>
                                               {item.image[0] && <img src={constants.Image_BASE_URL+""+item.image[0].fileName} />}
                                            </figure>
                                            <h4>{item.title}</h4>
                                            {parse(`${item.description}`)}
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className={Styles.wareHouseBank}>
                <Container className={Styles.organicSpace}>
                    <Row>
                        <Col>
                            <div className={Styles.bankElem}>
                                <figure>
                                  {bodyData.fields[1].image[0] &&  <img src={constants.Image_BASE_URL+""+bodyData.fields[1].image[0].fileName} />}
                                </figure>
                                <div className={Styles.bankcontent}>
                                    <h4>{bodyData.fields[1].title}</h4>
                                    {parse(`${bodyData.fields[1].description}`)}
                                </div>
                            </div>
                            <div className={Styles.WarehouseMatter}>
                                <h5>{bodyData.fields[2].title}</h5>
                                {parse(`${bodyData.fields[2].description}`)}
                                <figure>
                                  {bodyData.fields[2].image[0] &&  <img src={constants.Image_BASE_URL+""+bodyData.fields[2].image[0].fileName} />}
                                </figure>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.approach}>
                <Container className={Styles.organicSpace}>
                    <Row>
                        <Col>
                            <h5>{bodyData.fields[3].title}</h5>
                            {parse(`${bodyData.fields[3].description}`)}
                        </Col>
                    </Row>
                </Container>
            </div>
            </>
        )}
        </div>
    )
}

export default Warehouse;