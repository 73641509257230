import React from 'react';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
const ContactDetail = ({ nextStep, prevStep, handleChange, handleArrayChange, formData , errors }) => {
  console.log('form1 data===', formData)
  return (
    <div>
      <h5>Contact Details</h5>
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
            <input
            type="text"
            name="mobileNo"
            placeholder="Mobile Number"
            value={formData.contactDetails.mobileNo}
            onChange={handleChange('mobileNo', 'contactDetails')}
            />
            {errors.mobileNo && <span>{errors.mobileNo}</span>}
          </div>
          <div className={Styles.group}>
            <input
            type="email"
            name="emailId"
            placeholder="Email ID"
            value={formData.contactDetails.emailId}
            onChange={handleChange('emailId', 'contactDetails')}
          />
          {errors.emailId && <span>{errors.emailId}</span>}
          </div>
      </div>
      
      <div className={Styles.FormControl}>
          <div className={Styles.group}>
          <input
            type="text"
            name="currentLocation"
            placeholder="Current Location"
            value={formData.contactDetails.currentLocation}
            onChange={handleChange('currentLocation', 'contactDetails')}
          />
          {errors.currentLocation && <span>{errors.currentLocation}</span>}
          </div>
          
      </div>
      
      
      <button onClick={prevStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Previous</button>
      <button type="button" onClick={nextStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Next</button>
    </div>
  );
};

export default ContactDetail;
