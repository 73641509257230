import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import BtnStyle from '../../Style/Button.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeAPI, getKeyAspectsAPI } from '../../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import { IoCloseSharp } from "react-icons/io5";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
const CertificationPage = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.parentTab : ''; 

    const [CTFData, setCTFData] = useState([]);
    const [kayData, setKeyData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Promise.all([
            getServicesTypeAPI('Certification Services'),
            getKeyAspectsAPI('overview')
        ]).then(([certificationRes, keyAspectsRes]) => {
            setCTFData(certificationRes.data.homeserviceimage);
            setKeyData(keyAspectsRes.data.iconservice[0].fields);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, []);
    const adjustPath = (path) => {
       
        // Example function to adjust paths
        return path.replace('/Certification/', '/');
       
    };
    if (loading || CTFData.length === 0) {
        return <Loader />;
    }

    const OverView = CTFData[0];
    console.log(OverView)
    return(
        <div className={'CertificationPage ' + Styles.CertificationSec}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
              {BannerImage &&  <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.CTFOverview}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.ovewviewCOntainer}>
                                <Row>
                                    <Col>
                                        <div className={Styles.content}>
                                            <h2>{OverView.text}</h2>
                                            {parse(`${OverView.description}`)}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.keyAspects}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>Key Aspects</h3>
                            </div>
                            <ul>
                                {kayData &&
                                    kayData.map((item)=>{
                                        return(
                                            <li>
                                                <figure>
                                                  {item.icon &&  <img src={constants.Image_BASE_URL+""+item.icon} />}
                                                </figure>
                                                <h6>{item.title}</h6>
                                                {parse(`${item.description}`)}
                                            </li>
                                        )
                                    })
                                } 
                            </ul>
                        </Col>   
                    </Row>
                </Container>
            </div>
            <div className={Styles.CFTbenefit}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.CFTbenefitContainer}>
                                <h3>Benefits</h3>
                                <ul>
                                    <li>Enhanced credibility and trust</li>
                                    <li>Increased customer confidence and loyalty</li>
                                    <li>Improved marketability and competitiveness</li>
                                    <li>Access to new markets and business opportunities</li>
                                    <li>Risk reduction and compliance assurance</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.allCTF}>
                {CTFData &&
                    CTFData.sort((a, b) => a.order - b.order).map((item,i)=>{
                        const adjustedLink = adjustPath(item.link);
                        if(item.text !=='Warehousing'){
                        return(
                            <div className={Styles.CFTContainer} key={i}>
                               
                                <div className={Styles.CTFElem}>
                                    <div className={Styles.contentOrder}>
                                        <div className={Styles.content}>
                                            {item.imageicon && <span className={Styles.icon}><img alt={item.text} src={constants.Image_BASE_URL+""+item.imageicon} width='81' height='82' /></span>}
                                            {item.text!=="Safety-Audits" ?(
                                            <h4>{item.text}</h4>):(<h4>Audit Services</h4>)
                                                 }
                                            {parse(`${item.description}`)}
                                            <Link to={`/${adjustedLink}`}  title={item.text} className={BtnStyle.roundBtnLg + ' ' + BtnStyle.RoundRed}><img alt='' width='12' height='12' src={require('../../../assest/images/White_Arrow.png')} /></Link>
                                        </div>
                                    </div>
                                    <div className={Styles.imgOrder}>
                                        <figure>
                                           {item.innerimage && <img src={constants.Image_BASE_URL+""+item.innerimage} alt={item.text} width='670' height='486' />}
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        )}
                    })
                }
            </div>
            <div className={Styles.auditsSec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.auditsContainer}>
                                <h3>Audits</h3>
                                <div className={Styles.content}>
                                    <p>An energy audit is an inspection survey and an analysis of energy flows for energy conservation in a building. It may include a process or system to reduce the amount of energy input into the system without negatively affecting the output.

In commercial and industrial real estate, an energy audit is the first step in identifying opportunities to reduce energy expense and carbon footprint</p>
                                    <Link to='/safety-audits' title='Audits' className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Explore</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CertificationPage;