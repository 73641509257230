import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { getTataValueAPI } from '../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../services/constants';
import Loader from "../Common/PageLoader/LoadingSpinner";
import AutoTextChange from "../Common/PageLoader/AutoTextChange";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";

const TataValues = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [valueContent, setValueContent] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        getTataValueAPI('Tata')
            .then((res) => {
                setValueContent(res.data.values);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);
    if (loading) {
        return <Loader />;
    }

    const texts = valueContent.map(item => item.title);
    return(
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
              {BannerImage &&  <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail + ' ' + Styles.valueAnime}>
                                    <h2>{BannerTitle}</h2>
                                    <AutoTextChange texts={texts} interval={3000} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.ratanTata}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.ratanText}>
                                <p>Business, as I have seen it, places one great demand on you: it needs you to self-impose a framework of ethics, values, fairness and objectivity on yourself at all times.</p>
                                <span>- Ratan N Tata, 2006</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.tataValueSec}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.valueText}>
                               <h5 style={{textAlign:"center"}}> Tata has always been a values-driven organisation. </h5>
                                <p>  These values continue to direct the growth and business of Tata companies.</p>
                            </div>
                            <div className={Styles.valueBoxContainer}>
                                <Row>
                                    {valueContent &&
                                        valueContent.map((item)=>{
                                            return(
                                                <Col md={4} key={item._id} className={Styles.tableWidt}>
                                                    <div className={Styles.valueBoxElem}>
                                                        <figure>
                                                           {item.image && <img src={constants.Image_BASE_URL+""+item.image} />}
                                                        </figure>
                                                        <h5>{item.title}</h5>
                                                        {parse(`${item.description}`)}
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default TataValues;