import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { getItem } from '../../../services/routes.backend.services';
import constants from '../../../services/constants';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const JoinTeamSection = () => {
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);
  const [currentItem, setCurrentItem] = useState({ title: '', description: '', link: '', images: [] });
  const [editing, setEditing] = useState(false);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getItem('jointeams');
      setItems(response.data.jointeams);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setCurrentItem({ title: '', description: '', link: '', images: [] });
    setEditing(false);
  };

  const handleShow = () => setShow(true);

  const handleSave = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', currentItem.title);
    formData.append('description', currentItem.description);
    formData.append('link', currentItem.link);

    for (let i = 0; i < currentItem.images.length; i++) {
      formData.append('images', currentItem.images[i]);
    }

    try {
      if (editing) {
        const response = await axios.put(`${constants.API_BASE_URL}jointeams/${currentItem._id}`, formData);
        const updatedItems = [...items];
        updatedItems[index] = response.data;
        toast.success("Edited successfully");
        setItems(updatedItems);
        fetchData();
      } else {
        const response = await axios.post(`${constants.API_BASE_URL}jointeams`, formData);
        setItems([...items, response.data]);
        toast.success("Added successfully");
        fetchData();
      }
      handleClose();
    } catch (error) {
      console.error('Error saving item:', error);
      toast.error("There was an error !");
    }
  };

  const handleEdit = (items) => {
    setCurrentItem(items);
    setIndex(items._id);
    setEditing(true);
    handleShow();
  };

  const handleDelete = async (data) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
    try {
      await axios.delete(`${constants.API_BASE_URL}jointeams/${data._id}`);
      const filteredItems = items.filter((item, index) => index !== data._id);
      setItems(filteredItems);
      toast.success("Deleted successfully");
      fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error("There was an error !");
    }
    }
  };

  const handleImageDelete = async (data,fileName) => {
    try {
      await axios.delete(`${constants.API_BASE_URL}jointeams/teamimage/${data._id}`, {
        data: { fileName } // Send fileName in the request body
      });
      const filteredItems = items.filter((item, index) => index !== data._id);
      setItems(filteredItems);
      toast.success("Image Deleted successfully");
      fetchData();
    } catch (error) {
      console.error('Error deleting team image:', error);
      toast.error("There was an error !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem({ ...currentItem, [name]: value });
  };

  const handleImageChange = (e) => {
    setCurrentItem({ ...currentItem, images: [...e.target.files] });
  };

  return (
    <Container >
             <ToastContainer />
      <Row className="my-4">
        <Col>
          <Button onClick={handleShow}>Add Join team details</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Title</th>
                <th>Description</th>
                <th>Link</th>
                <th>Images</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) => (
                <tr key={idx}>
                  <td>{idx+1}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td><a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a></td>
                  <td>
                    {item.image.map((img, i) => (
                      <span key={i}>
                      <img  src={`${constants.Image_BASE_URL}${img.fileName}`} alt="" width="50" />
                      <Button variant="danger" onClick={() => handleImageDelete(item,img.fileName)}><FaTrash /></Button></span>
                    ))}
                  </td>
                  <td>
                    <Button variant="warning" onClick={() => handleEdit(item)}> <FaEdit /></Button>
                    {' '}
                    <Button variant="danger" onClick={() => handleDelete(item)}><FaTrash /></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editing ? 'Edit Item' : 'Add Item'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSave}>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={currentItem.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={currentItem.description} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control type="text" name="link" value={currentItem.link} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Images</Form.Label>
              <Form.Control type="file" name="images" multiple onChange={handleImageChange} />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              <Button variant="primary" type="submit">{editing ? 'Update' : 'Save'}</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default JoinTeamSection;
