import React from 'react';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
const EducationDetail = ({ nextStep, prevStep, handleChange, handleArrayChange, formData , errors }) => {
  return (
    <div>
      <h5>Education Details</h5>
      {formData.educationDetails.map((edu, index) => (
        
        <div key={index}>
          <div className={Styles.FormControl}>
            <div className={Styles.group}>
              <input
                type="text"
                name="relevantQualification"
                placeholder="Relevant Qualification"
                value={edu.relevantQualification}
                onChange={handleArrayChange(index, 'educationDetails', 'relevantQualification')}
              />
              {errors.relevantQualification && <span>{errors.relevantQualification}</span>}
            </div>
            <div className={Styles.group}>
              <input
                type="text"
                name="specialization"
                placeholder="Specialization"
                value={edu.specialization}
                onChange={handleArrayChange(index, 'educationDetails', 'specialization')}
              />
              {errors.specialization && <span>{errors.specialization}</span>}
            </div>
            <div className={Styles.group}>
              <select
              name="mode"
              value={formData.mode}
              onChange={handleArrayChange(index, 'educationDetails', 'mode')}
              required
            >
              <option value="">Select Mode</option>
              <option value="Full-Time">Full-Time</option>
              <option value="Part-Time">Part-Time</option>
              <option value="Distance">Distance</option>
            </select>


              {errors.mode && <span>{errors.mode}</span>}
            </div>
          </div>
        </div>
      ))}

      <h5>Professional Certifications</h5>
      {formData.professionalCertifications.map((cert, index) => (
        <div key={index}>
          <div className={Styles.FormControl}>
            <div className={Styles.group}>
            <input
              type="text"
              name="certificateName"
              placeholder="Certificate Name"
              value={cert.certificateName}
              onChange={handleArrayChange(index, 'professionalCertifications', 'certificateName')}
            />
            {errors.certificateName && <span>{errors.certificateName}</span>}
            </div>
            <div className={Styles.group}>
              <select
                name="validity"
                value={cert.validity}
                onChange={handleArrayChange(index, 'professionalCertifications', 'validity')}
                required
              >
              <option value="">Select Mode</option>
              <option value="Valid ">Valid </option>
              <option value="To be Renewed">To be Renewed</option>
              
            </select>
            {errors.validity && <span>{errors.validity}</span>}
            </div>
            </div>
        </div>
      ))}
      
      <button onClick={prevStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Previous</button>
      <button type="button" onClick={nextStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Next</button>
    </div>
  );
};

export default EducationDetail;
