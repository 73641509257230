import React from 'react';

const Loader = () => {
    return (
        <div className='sectionLoader'>
             <img src={require('../../../assest/images/Qcert-logo.png')}/>
        </div>
    );
}

export default Loader;
