import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../services/constants';

const LeaderShipDetail = ()=>{

    return(
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                <img src={require('../../assest/images/board-birectors-banner.jpg')} className={Styles.innerBannerimg} />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>Senior Leadership Team</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.leaderDetail}>
                <Container>
                    <Row>
                        <Col md={12}>
                            
                        </Col>
                        <Col md={5}>
                            <div className={Styles.seniorLeaderDetailImg}>
                                <figure>
                                    <img src={require('../../assest/images/VinayakPai-ceo.jpg')} alt="" title="" />
                                </figure>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className={Styles.seniorLeaderDetail}>
                                <h3>Mr. Vinayak Pai</h3>
                                <h4>MD and CEO</h4>
                                <p>Vinayak is the MD & CEO of Tata Projects Limited (TPL), India’s Sustainable Technology led EPC (Engineering, Procurement & Construction) company. Tata Projects delivers EPC solutions for the Infrastructure, Buildings, Energy & Industrial sectors. Some of the TPL’s marquee projects are the New Parliament Building, Mumbai Trans Harbour Link, Noida International Airport, HPCL Rajasthan Refinery Ltd., Tata Electronic factory, ISRO – Trisonic Wind Tunnel, Chennai Metro, etc.</p>
                                <p>Prior to Tata Projects, Vinayak has worked in US & Europe leading global businesses in over 50 countries, first as President – ECR at Jacobs, one of America’s leading EPC company and later as Group President Energy and Chemical Services business at Worley, Australia’s top EPC company.</p>
                                <p>A respected voice in the industry, Vinayak is the Vice-Chairman of Confederation of Indian Industry (CII) Maharashtra and Chairman of the National committee of Roads & Highways. He is on the Board of Governors of CIDC for the term 2023-25. He is also the President of Construction Federation of India (CFI) and in the past has been a member of Executive Leader World 50 and a Commissioner at the Energy Transition Commission UK.</p>
                                <p>An alumnus of the College of Engineering (Pune) with a Postgraduate degree in Management from Symbiosis College, Pune, and an Executive MBA from IIT Bombay’s Shailesh J. Mehta School of Management, Vinayak is also a member of the board of Governors of COEP technology university, Pune and First Construction Council of India.</p>
                                <p>Vinayak’s groundbreaking contributions have earned him accolades including the Hall of Fame Award as an Infra Industry Leader by Construction Week, the distinguished alumni Abhimaan Award by the College of Engineering Pune, and the prestigious 15th CIDC Vishwakarma Doyen of the Industry 2024 award.</p>
                                <ul>
                                    <li><Link to='/' title=""><img src={require('../../assest/images/linkedin-icon.png')} /></Link></li>
                                    <li><Link to='/' title=""><img src={require('../../assest/images/web-icon.png')} /></Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default LeaderShipDetail;