import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterStyle from '../../Style/Footer.module.css';
import { Link } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube    } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { getFooterAddress } from "../../../services/routes.services";
import { useSelector } from 'react-redux';
const Footer =()=>{
    const [address, setAddress] = useState([]);
    useEffect(()=>{
        getFooterAddress().then((res)=>{
            setAddress(res.data.addresses[0])
        })
    }, [])
    const { menu } = useSelector(state => state.header);
    if (!menu || menu.length === 0) {
        return null;
    }
    const sortedMenu = [...menu].sort((a, b) => a.orderNumber - b.orderNumber);
    const AssuranceLink = sortedMenu[1].children[0].children;
    const ProjectLink1 = sortedMenu[1].children[1].children;
    const ProjectLink = [...ProjectLink1].sort((a, b) => a.orderNumber - b.orderNumber);
    const CertificationLink = sortedMenu[1].children[2].children;
    const AboutLink = sortedMenu[0].children;
    return(
        <footer className={FooterStyle.FooterMain}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className={FooterStyle.boc}>
                            <h6>Services</h6>
                        </div>
                        <Row>
                            <Col md={4} className={FooterStyle.mobWid}>
                                <h5>Assurance Services</h5>
                                <ul>
                                    {AssuranceLink.map((item) => {
                                        return (
                                            <li key={item.orderNumber}>
                                                <Link to={item.linkUrl} title={item.linkText}>{item.linkText}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Col>
                            <Col md={4} className={FooterStyle.mobWid}>
                                <h5>Project Services</h5>
                                <ul>
                                    {ProjectLink.map((item) => {
                                        return (
                                            <li key={item.orderNumber}>
                                                <Link to={item.linkUrl} title={item.linkText}>{item.linkText}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Col>
                            <Col md={4} className={FooterStyle.mobWid}>
                                <h5 className={FooterStyle.CTFSpace}>Certification Services</h5>
                                <ul>
                                    {CertificationLink.map((item) => {
                                        return (
                                            <>
                                                {item.linkText !=='Wearhousing' &&
                                            <li key={item.orderNumber}>
                                                <Link to={item.linkUrl} title={item.linkText}>{item.linkText}</Link>
                                            </li>
                                    }</>
                                        );
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} xs={6} className={FooterStyle.colmSpace}>
                        <div className={FooterStyle.boc}>
                            <h6>About</h6>
                        </div>
                        <ul>
                            {AboutLink.map((item) => {
                                return (
                                    <li key={item.orderNumber}>
                                        <Link to={item.linkUrl} title={item.linkText}>{item.linkText}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className={FooterStyle.topSpace}>
                            <h6>More Info</h6>
                        </div>
                        <ul>
                            {sortedMenu.map((item) => {
                                return (
                                    <li key={item.orderNumber}>
                                        <Link to={item.linkUrl} title={item.linkText}>{item.linkText}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className={FooterStyle.boc}>
                            {/* <form>
                                <input type="text" placeholder="Let`s Talk" />
                                <button type="button"><img src={require('../../../assest/images/Black-Arrow.png')} /></button>
                            </form> */}
                            {/* <ul className={FooterStyle.socialLink}>
                                <li><Link to='/'><FaFacebookF /> </Link></li>
                                <li><Link to='https://in.linkedin.com/company/tqcertservices'  target="_blank"><FaLinkedinIn /> </Link></li>
                                <li><Link to='/'><FaInstagram /> </Link></li>
                                <li><Link to='/'><FaYoutube /> </Link></li>
                                <li><Link to='/'><IoMdMail /></Link></li>
                            </ul> */}
                            {address && 
                                <p><img src={require('../../../assest/images/call-icon.png')} /><Link to="tel:+914067258800">(040)-67258800</Link></p>
                            }
                            {address &&
                                <p><img src={require('../../../assest/images/mail-icon.png')} /><Link to={`mailto:${address.email}`}>{address.email}</Link></p>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={FooterStyle.copyRight}>
                <Container>
                    <Row>
                        <Col>
                            <div className={FooterStyle.copyFlex}>
                                <p>© Copyright 2024. TQ Cert. All Rights Reserved.</p>
                                <p>Design and Development by <img src={require('../../../assest/images/olive-logo.png')} /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer;