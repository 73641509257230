import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import constants from '../../services/constants';
import parse from 'html-react-parser';
const WeOfferSection = (props) => {
    const { Offer: weOffer, OffeTab: weOfferTab } = props;
  
    const assuranceResponsive = {
        superLargeDesktop: {
            breakpoint: { max: 3000, min: 1620 },
            shouldResetAutoplay: true,
            items: 5.2,
        },
        desktop: {
            breakpoint: { max: 1600, min: 1201 },
            items: 4.2,
            shouldResetAutoplay: true,
        },
        desktopMin: {
            breakpoint: { max: 1200, min: 840 },
            items: 3.2,
            shouldResetAutoplay: true,
        },
        tab: {
            breakpoint: { max: 839, min: 580 },
            items: 2.2,
            shouldResetAutoplay: true,
        },
        mobile: {
            breakpoint: { max: 579, min: 0 },
            items: 2,
            shouldResetAutoplay: true,
        }
    };
    //console.log(weOfferTab.child)
    return (
        <div className={'WeOffer ' + Styles.OfferSec}>
            <Container>
                <Row>
                    <Col>
                        {weOffer &&
                            <div className={Styles.offerHeading +" " + Styles.MobShow}>
                                <h3>{weOffer.title}</h3>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
            <Tabs className={Styles.tabContainer}>
                <div className={Styles.leftArea}>
                    <TabList className='offTabStyle'>
                        {weOfferTab.map((item) => (
                            <Tab key={item._id}>{item.title}</Tab>
                        ))}
                    </TabList>
                </div>
                <div className={Styles.RightArea}>
                    {weOfferTab.map((item)=>{
                        return(
                            <TabPanel key={item._id}>
                                <div className={Styles.offerHeading}>
                                    <h3>What We Offer</h3>
                                    {parse(`${item.description}`)}
                                </div>
                            <Carousel
                                autoPlaySpeed={2000}
                                transitionDuration={500}
                                responsive={assuranceResponsive}
                                autoPlay={true}
                                infinite={true}
                                arrows={false}
                                containerClass="assuranceOwl"
                            >
                                {item.child.map((tab) => (
                                    <div className={Styles.OfferOwlContainer} key={tab._id}>
                                        <figure>
                                            {tab.image &&<img src={`${ constants.Image_BASE_URL}${tab.image}`} alt={tab.text} width='282' height='358'  />}
                                        </figure>
                                        <div className={Styles.OfferOwlElem}>
                                            <h5>{tab.text}</h5>
                                            {tab.text !== "Overview" ?
                                            (<Link to={tab.link} title={tab.text} className={BtnStyle.roundBtn + ' ' + BtnStyle.RoundRed}>
                                                <img alt={tab.text} width='12' height='12' src={require('../../assest/images/White_Arrow.png')} />
                                            </Link>):( <Link to={item.link} title={tab.text} className={BtnStyle.roundBtn + ' ' + BtnStyle.RoundRed}>
                                                <img alt={tab.text} width='12' height='12' src={require('../../assest/images/White_Arrow.png')} />
                                            </Link>)
                                            }
                                        </div>
                                    </div>
                                ))}

                            </Carousel>
                            <div className={Styles.buttonFlex}>
                               {item.link =="assurance-services" && <Link to="assurance-service" title={item.link} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>View all Services</Link>}
                               {item.link =="project-services" && <Link to="project-services" title={item.link} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>View all Services</Link>}
                               {item.link =="certification-services" && <Link to="certification" title={item.link} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>View all Services</Link>}

                               
                            </div>
                        </TabPanel>
                        )
                    })}
                   
                </div>
            </Tabs>
        </div>
    );
}

export default WeOfferSection;
