import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
import { Link, useLocation } from "react-router-dom";
import Loader from "../Common/PageLoader/LoadingSpinner";
import axios from "axios";
import { getAllJobs,getLocationArray,getRoleArray,getSpecialisationArray } from "../../services/jobService";
import { getCareerAPI } from "../../services/routes.services";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
import constants from "../../services/constants";
//import { parse } from "json2csv";
import parse from 'html-react-parser';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Careers=()=>{
     //math Path
     const location = useLocation();
     const { menu } = useSelector(state => state.header);
     const currentPageUrl = location.pathname;
     const currentMenuItem = MenuPath(menu, currentPageUrl);
     const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
     const BannerTitle = currentMenuItem ? currentMenuItem.linkText : '';

    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [filters, setFilters] = useState({
    role: '',
    location: '',
    skill: '',
    experience: '',
    });
    const [error, setError] = useState(null);
    const [query, setQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [getRole, setRole] = useState([]);
    const [getLocation, setLocation] = useState([]);
    const [getSpecialisation, setSepecialisation] = useState([]);
    const [getExperience, setExperience] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);
    const [careerData, setCareerData] = useState([]);
    const limit = 10; // Number of jobs per page
    const autoPlayTime = 3000;
    useEffect(() => {
        getCareerAPI().then((res)=>{
            setCareerData(res.data.carrerhome);
            setLoading(false)
        }).catch(error=>{
            console.log(error)
            setLoading(false)
        })
    }, []);
    // console.log(careerData)
    useEffect(() => {
        fetchJobs()
    }, [currentPage]);
    useEffect(()=>{
        const fetchRoles = async () => {
            try {
              const roleData = await getRoleArray();
              
              if (roleData.data.success) {
                setRole(roleData.data.data);
              } else {
                setError('Failed to fetch role');
              }
            } catch (err) {
              setError('Error fetching data');
              console.error(err);
            }
        };

        const fetchLocation = async () => {
            try {
              const locationData = await getLocationArray();
              
              if (locationData.data.success) {
                setLocation(locationData.data.data);
              } else {
                setError('Failed to fetch location');
              }
            } catch (err) {
              setError('Error fetching data');
              console.error(err);
            }
        };

        const fetchSpecialisation = async () => {
            try {
              const specialisationData = await getSpecialisationArray();
              
              if (specialisationData.data.success) {
                setSepecialisation(specialisationData.data.data);
              } else {
                setError('Failed to fetch location');
              }
            } catch (err) {
              setError('Error fetching data');
              console.error(err);
            }
        };
      
          fetchRoles();
          fetchLocation();
          fetchSpecialisation();
    },[])
    useEffect(() => {
        // Filter jobs whenever the filters change
        const applyFilters = () => {
          const { role, location, skill, experience } = filters;
    
          const filtered = jobs.filter((job) => {
            return (
              (role ? job.positionTitle === role : true) &&
              (location ? job.location === location : true) &&
              (skill ? job.specialization === skill : true) &&
              (experience
                ? Number(experience) >= job.minExperience && Number(experience) <= job.maxExperience
                : true)
            );
          });
    
          setFilteredJobs(filtered);
        };
    
        applyFilters();
      }, [filters, jobs]);
      const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
        }));
      };


    const fetchJobs = async () => {
        try {
          const response = await getAllJobs(currentPage,limit);
          // Ensure response.data is an array
          if (Array.isArray(response.data.data)) {
            setJobs(response.data.data);
            setFilteredJobs(response.data.data)
            setTotalPages(response.data.totalPages);
          } else {
            console.error('Expected an array but received:', response.data);
            setJobs([]); // Set to an empty array if not in expected format
          }
        } catch (error) {
          console.error('Error fetching jobs:', error);
          setJobs([]); // Set to an empty array in case of error
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleSearchClick = () => {
        const { role, location, skill, experience } = filters;
    
          const filtered = jobs.filter((job) => {
            return (
              (role ? job.positionTitle === role : true) &&
              (location ? job.location === location : true) &&
              (skill ? job.specialization === skill : true) &&
              (experience
                ? Number(experience) >= job.minExperience && Number(experience) <= job.maxExperience
                : true)
            );
          });
    
          setFilteredJobs(filtered);
      };

       // Handle reset button click
  const handleResetClick = () => {
    setFilters({
        role: '',
        location: '',
        skill: '',
        experience: '',
    });
    setQuery('')
    fetchJobs()
    setFilteredJobs(jobs); // Reset to show all jobs
  };
   
    const handleNext = () => {
        if (careerData[0] && careerData[0].image.length) {
            setCurrentIndex((currentIndex + 1) % careerData[0].image.length);
        }
    };

    const handlePrev = () => {
        if (careerData[0] && careerData[0].image.length) {
            setCurrentIndex((currentIndex - 1 + careerData[0].image.length) % careerData[0].image.length);
        }
    };
    
      useEffect(() => {
        const timer = setInterval(() => {
            handleNext();
        }, autoPlayTime);
    
        return () => clearInterval(timer);
      }, [currentIndex, autoPlayTime]);

    if (loading) {
        return <Loader />;
    }
    const lookingResponsive = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 0 },
          shouldResetAutoplay:true,
          items: 1,
        },
      };

      const handleSearch = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.get(`${constants.API_BASE_URL}jobs/search`, {
            params: { query }, // Pass query as a parameter
          });
          setJobs(response.data); // Set the jobs to display
          setError(''); // Clear any previous error
        } catch (err) {
          if (err.response && err.response.status === 404) {
            setError('No jobs found matching the query');
          } else {
            setError('An error occurred while searching for jobs');
          }
          setJobs([]); // Clear previous jobs
        }
      };
    return(
        <div className={'CertificationPage ' + Styles.CareerPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
              {BannerImage &&  <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.lokingFor}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>What Are We Looking For?</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className={'lookingSec ' + Styles.lookingSec}>
                    <Container className={Styles.lookSpace}>
                        <Row>
                        {careerData && careerData.length > 0 &&
                        <>
                                {careerData.slice(0, 3).map((slideImg, index) => {
                                    return(
                                        <Col md={4}>
                                            <div className={Styles.loginContainer}>
                                                <div className={Styles.lookingSlider}>
                                                    <figure>
                                                        <img className="img-fluid" src={constants.Image_BASE_URL+""+slideImg.image[0].fileName} />
                                                    </figure>
                                                </div>
                                                <div className={Styles.content}>
                                                    <h4>{slideImg.title}</h4>
                                                    <p> {parse(`${slideImg.description}`)}</p>
                                                    {/* <Link to={careerData[0].link} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Open POSITIONS</Link> */}
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                                </>
                            }
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.joinTata}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.joinSpace}>
                                {careerData && careerData.length > 0 &&
                                    <div className={Styles.tatContainer}>
                                        <div className={Styles.content}>
                                            <h4>{careerData[3].title}</h4>
                                            {parse(`${careerData[3].description}`)}
                                            {/* <Link to={careerData[1].link} className={BtnStyle.btn + ' ' + BtnStyle.BlurBtn}>JOIN US</Link> */}
                                        </div>
                                        <div className={Styles.contentImg}>
                                            <figure>
                                              {careerData[3].image[0] &&  <img src={constants.Image_BASE_URL+""+careerData[3].image[0].fileName} alt="TQcert" width='295' height='171' />}
                                            </figure>
                                        </div>
                                    </div>   
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.workBlock} id="career">
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>Working With Us</h3>
                            </div>
                            <div className={Styles.jobListingBox}>
                                <div className={Styles.jobForm}>
                                    <div className={Styles.jobGroup}>
                                        
                                        <select id="role" name="role" onChange={handleFilterChange} value={filters.role}>
                                        <option value="">Role</option>
                                        {getRole.sort((a, b) => a.localeCompare(b)).map((role, index) => (
                                        <option key={index} value={role}>
                                        {role}
                                        </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className={Styles.jobGroup}>
                                        <select id="location" name="location" onChange={handleFilterChange} value={filters.location}>
                                        <option value="">Location</option>
                                        {getLocation.sort((a, b) => a.localeCompare(b)).map((loc, index) => (
                                        <option key={index} value={loc}>
                                        {loc}
                                        </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className={Styles.jobGroup}>
                                        <select id="skill" name="skill" onChange={handleFilterChange} value={filters.skill}>
                                        <option value="">Skills</option>
                                        {getSpecialisation.sort((a, b) => a.localeCompare(b)).map((skil, index) => (
                                        <option key={index} value={skil}>
                                        {skil}
                                        </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className={Styles.jobGroup}>
                                        <select id="experience" name="experience" onChange={handleFilterChange} value={filters.experience}>
                                        <option value="">Experience</option>
                                        {getExperience.map((exp, index) => (
                                        <option key={index} value={exp}>
                                        {exp}
                                        </option>
                                        ))}
                                        </select>
                                        
                                    </div>
                                        <div className={Styles.jobGroup}>
                                        <input
                                        type="text"
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                        placeholder="Search by position title, location, education,employment type..."
                                        />
                                        </div>
                                    <div className={Styles.jobGroup}>
                                        <button type="button" onClick={handleSearch}>Search</button>
                                        <button type="button" onClick={handleResetClick}>Reset</button>
                                    </div>
                                </div>
                                <div className={Styles.jobList}>
                                    <table>
                                        <tr className={Styles.blueBg}>
                                            <th>Job ID</th>
                                            <th>Position Title</th>
                                            <th>Preferred Industry</th>
                                            <th>Location</th>
                                            <th>Vacancies</th>
                                            <th>Employment Type</th>
                                            <th></th>
                                        </tr>
                                        {filteredJobs.length > 0 ? (
                                            filteredJobs.map((job) => (
                                                <tr>
                                                    <td>{job.jobId}</td>
                                                    <td>{job.positionTitle}</td>
                                                    <td>{job.preferredIndustry}</td>
                                                    <td>{job.location}</td>
                                                    <td>{job.numberOfVacancies}</td>
                                                    <td>{job.employmentType}</td>
                                                    <td><Link className={BtnStyle.btn + ' ' + BtnStyle.lightBlue} to={`/job-position`} state={{ job }}>View Job</Link></td>
                                                </tr>
                                            ))
                                            ):(
                                                <p>No data found</p>
                                            )}
                                        
                                    </table>
                                    {totalPages >='2' ?(
                                    <div className="pagination">
                                        {Array.from({ length: totalPages }, (_, index) => (
                                        <button
                                        key={index + 1}
                                        onClick={() => handlePageChange(index + 1)}
                                        disabled={currentPage === index + 1}
                                        >
                                           
                                        {index + 1}
                                        </button>
                                        ))}
                                    </div>):(null)}
                                    {/* <div className={Styles.centerAlight}>
                                        <Link className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Open POSITIONS</Link>
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.jobOpening}>
                {careerData && careerData.length > 0 &&
                    <>
                        {careerData[4].image[0] &&<img src={constants.Image_BASE_URL+""+careerData[4].image[0].fileName} className={Styles.jobImg} />}
                        <img src={require('../../assest/images/Mask.png')} className={Styles.jobMask} />
                        <div className={Styles.openingContainer}>
                            <Container>
                                <Row>
                                    <Col>
                                        <div className={Styles.openingElem}>
                                            <h4>{careerData[4].title}</h4>
                                            <div className={Styles.content}>
                                            
                                                <p>{parse(`${careerData[4].description}`)}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>   
                    </>
                }
            </div>
        </div>
    )
}

export default Careers;