import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getServicesTypeDetailAPI, getWhyChooseusAPI, getKeyAspectsAPI } from '../../../services/routes.services';
import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import Breadcrumb from "./BreadCrumb";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
const DeskServices = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [qualityData, setQualityData] = useState([]);
    const [whyChooseus, setWhyChooseus] = useState([]);
    const [deliveryData, setDeliveryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const autoPlayTime = 3000;
  
    useEffect(() => {
      Promise.all([
        getServicesTypeDetailAPI('desk-services'),
        getWhyChooseusAPI('desk-services'),
        getKeyAspectsAPI('desk-services')
      ]).then(([qualityRes, chooseRes, deliveryRes]) => {
        setQualityData(qualityRes.data.servicedetails[0]);
        setWhyChooseus(chooseRes.data.whychooseus[0]);
        setDeliveryData(deliveryRes.data.iconservice[0])
        setLoading(false);
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
    }, []);
  
    const TQSlider = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 0 },
          shouldResetAutoplay:false,
          items: 1.6,
          slidesToSlide: 1
        },
    };
    if (loading || !whyChooseus.fields || whyChooseus.fields.length === 0) {
      return <Loader />;
    }
    return(
        <div className={'assuracePage ' + Styles.aservicesPage +" " +Styles.brownBg}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
             {BannerImage &&   <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.AsOverView +" " +Styles.brownBg}>
                <Container>
                    <Row>
                        {qualityData && 
                            <>
                                <Col className={Styles.AsPaddingLeft}>
                                    <div className={Styles.AsImg}>
                                        <figure className={Styles.imgBgStyle}>
                                           {qualityData.image && <img src={constants.Image_BASE_URL+""+qualityData.image} alt="" width='631' height='443' />}
                                        </figure>
                                    </div>
                                </Col>
                                <Col className={Styles.AsPaddingRight}>
                                    <div className={Styles.AsContent}>
                                        <h3>{qualityData.titlename}</h3>
                                        {parse(`${qualityData.description}`)}
                                    </div>
                                </Col>  
                            </>
                        }
                    </Row>
                </Container>
            </div>
            <div className={Styles.SqKey +" " + Styles.UlStyle}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>Key Activities</h3>
                            </div>
                            <div className={Styles.SqKeyContainer}>
                                {qualityData.fields &&
                                    qualityData.fields.map((item)=>{
                                        return(
                                            <div className={Styles.SqKeyElem} key={item._id}>
                                                <div>
                                                    <figure>
                                                       {item.image[0] && <img src={constants.Image_BASE_URL+""+item.image[0].fileName} alt="" width='569' height='251' />}
                                                    </figure>
                                                    <div className={Styles.content}>
                                                        <h5>{item.title}</h5>
                                                        {parse(`${item.description}`)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.SqModel}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>Delivery Model</h3>
                            </div>
                            <div className={Styles.modelContainer}>
                                {deliveryData.fields &&
                                    deliveryData.fields.map((item, index)=>{
                                        return(
                                            <div className={Styles.modelBox} key={index}>
                                                <figure>
                                                  {item.icon &&  <img src={constants.Image_BASE_URL+""+item.icon} />}
                                                </figure>
                                                <h6>{item.title}</h6>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.Sqodel}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>{whyChooseus.subtitle}</h3>
                                {whyChooseus.description === 'undefined' ? null : parse(`${whyChooseus.description}`)}
                            </div>
                            <div className={Styles.valueBoxContainer}>
                                <Row>
                                    {whyChooseus.fields &&
                                        whyChooseus.fields.map((item)=>{
                                            return(
                                                <Col md={4} key={item._id} className={Styles.tableWidt}>
                                                    <div className={Styles.valueBoxElem}>
                                                      {item.image &&  <figure>
                                                            {item.image.length === 2 ? <img src={constants.Image_BASE_URL+""+item.image[1].fileName} /> : null }
                                                        </figure>}
                                                        <h5>{item.title}</h5>
                                                        <div className={Styles.pBorder}>{item.description === 'undefined' ? null : parse(`${item.description}`)}</div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DeskServices;