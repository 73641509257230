import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import TQRank from "./TQRank";
import parse from 'html-react-parser';
import BtnStyle from '../Style/Button.module.css';
import { Link } from "react-router-dom";
import { getWhoWeAreAPI } from "../../services/routes.services";


const WhoWeAreSection = (props)=>{
    const weAreData = (props.WeAre)
    //console.log(weAreData)
    return(
        <div className={'WeAre ' + Styles.WhoWeAre}>
            <Container>
                <Row>
                    <Col>
                        {weAreData &&
                            <div className={Styles.AreContainer}>
                                <div className={Styles.BoxFlex}>
                                    <h2>{weAreData.text}</h2>
                                    <div className={Styles.Boxcontent}>
                                        {/* <p>TQ Cert Services Private Limited (TQ Cert) a wholly owned subsidiary of Tata Projects Limited (TPL) is a leading TIC service provider operating across the globe through its Line of Businesses (LoB) Assurance Services, Project Services and Certification Services.</p>
                                        <p>TQ Cert, A Tata Enterprise, your trusted partner with 40+ years of expertise operating across 50+ countries and reputed for its agility, responsiveness, and on-time high-quality service delivery.</p> */}
                                        <p>{parse(`${weAreData.description}`)}</p>
                                        {/* <Link to='/' className={BtnStyle.btn + ' ' + BtnStyle.BlurBtn}>Know More</Link> */}
                                    </div>
                                </div>
                                <TQRank  />
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WhoWeAreSection;