import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";
import { getServicesTypeDetailAPI, getXLSDownloadAPI} from '../../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../../services/constants';
import Loader from "../../Common/PageLoader/LoadingSpinner";
import Breadcrumb from "./BreadCrumb";
import { useSelector } from 'react-redux';
import MenuPath from "../../Common/Header/CurrentPath";
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const AyushCertification = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [productData, setProductData] = useState([]);
    const [xlsData, setXlsData] = useState([]);
    const [vcsmppData, setVcsmppData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true)
        Promise.all([
            getServicesTypeDetailAPI('product-certification'),
            getXLSDownloadAPI('Ayush'),
            getXLSDownloadAPI('VCSMPP'),
        ]).then(([productRes, xlsFileRes, vcsmppRes])=>{
            setProductData(productRes.data.servicedetails[0]);
            setXlsData(xlsFileRes.data.customers);
            setVcsmppData(vcsmppRes.data.customers);
        }).catch(error =>{
            console.log(error)
        }).finally(()=>{
            setLoading(false)
        });
    }, [])

    if (loading) {
        return <Loader />;
    }
   const downloadXLSFile = async (image, name) => {
        const link = document.createElement('a');
    link.href = image; // Use the direct image link
    link.target = '_blank'; // Open in a new tab
    link.download = name; // Specify the download name
    document.body.appendChild(link);
    link.click(); // Programmatically click the link
    link.remove(); // Remove the link from the DOM
    
    };
    return(
        <div className={'CertificationPage tabbingStyle ' + Styles.AyushSec  + ' ' + Styles.CFTStyleSame}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
             {BannerImage &&   <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.ayushOverView}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.centerAlight}>
                                <h3>AYUSH Certification</h3>
                                <figure>
                                    <img src={require('../../../assest/images/ayush_product.png')} />
                                </figure>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.AsPdf}>
                <Container>
                    <Row>
                        <Col md={3} className={Styles.flexCenter}>
                            <h3>Our Clients</h3>
                        </Col>
                        <Col md={9}>
                            <div className={Styles.AsPDFContainer}>
                                {xlsData &&
                                    xlsData.map((item)=>{
                                        return(
                                            <div className={Styles.box} key={item.order}>
                                                <button type="button" onClick={() => downloadXLSFile(constants.Image_BASE_URL + item.image, item.name)}>
                                                    <figure>
                                                        <img src={require('../../../assest/images/xlxs_icon.png')} className={Styles.xlxsIcon} alt={item.name} />
                                                    </figure>
                                                    <h6>{item.name}</h6>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.foodTabbing}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.foodContainer}>
                                <Tabs className={Styles.foodtabContainer}>
                                    <TabList className={Styles.tabStyleFood}>
                                        {productData.fields && productData.fields.length > 0 &&
                                            [0, 2, 3, 4].map((index) => {
                                                const item = productData.fields[index];
                                                return (
                                                    <Tab key={item._id}>{item.title}</Tab>
                                                );
                                            })
                                        }
                                    </TabList>
                                    <div className={Styles.foodBodyContainer}>
                                        {productData.fields && productData.fields.length > 0 &&
                                            [0, 2, 3, 4].map((index) => {
                                                const item = productData.fields[index];
                                                return (
                                                    <TabPanel className={Styles.foodBody} key={item._id}>
                                                        <div className={Styles.foodTabContent}>  
                                                            {parse(`${item.description}`)}
                                                            {item.title==="Voluntary Certification For AYUSH"?(
                                                                <div className={Styles.ayushMask}>
                                                                    <h5 className={Styles.greenColor}><span>1</span> AYUSH STANDARD MARK</h5>
                                                                    <h5>Compliance to Domestic requirements or Schedule T compliance</h5>
                                                                    <h5 className={Styles.yellowColor}><span>2</span> AYUSH PREMIUM MARK</h5>
                                                                    <h5>Compliance to the GMP Requirements based on WHO Guidelines</h5>
                                                                    <table>
                                                                        <tr>
                                                                            <th>AYUSH STANDARD MARK</th>
                                                                            <th>AYUSH PREMIUM MARK</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>For Domestic Market</td>
                                                                            <td>For International Market</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Assessed for   Domestic regulatory requirements</td>
                                                                            <td>Compliance to the domestic regulation and having in house testing facilities is a   prerequisite.</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Domestic regulation – Schedule T compliance</td>
                                                                            <td>WHO GMP compliance</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Surveillance evaluation: once in a year Certificate validity: 3 years</td>
                                                                            <td>Surveillance evaluation: once in a year Certificate validity: 3 years</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Benefits
                                                                                <ul>
                                                                                    <li>Quality Mark</li>
                                                                                </ul>
                                                                            </td>
                                                                            <td>
                                                                                Benefits
                                                                                <ul>
                                                                                    <li>Quality Mark</li>
                                                                                    <li>Accepted/Mandatory for Govt. supply</li>
                                                                                    <li>Provides basis for obtaining CoPP for export</li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            ) : 
                                                                parse(`${item.description2}`)    
                                                            }  
                                                            {item.title=== "RMC Certification" ? (
                                                                <figure className={Styles.RMCIMg}>
                                                                    <div>{item.image[0] && <img src={constants.Image_BASE_URL+item.image[0].fileName} width="896" height="322" alt="Image" />}</div>
                                                                    <div>{item.image[1] && <img src={constants.Image_BASE_URL+item.image[1].fileName} width="896" height="322" alt="Image" />}</div>
                                                                    <div>{item.image[2] && <img src={constants.Image_BASE_URL+item.image[2].fileName} width="896" height="322" alt="Image" />}</div>
                                                                    <div>{item.image[3] && <img src={constants.Image_BASE_URL+item.image[3].fileName} width="896" height="322" alt="Image" />}</div>
                                                                </figure>
                                                            ) : 
                                                            <figure>
                                                                {item.image[0] && <img src={constants.Image_BASE_URL+item.image[0].fileName} width="896" height="322" alt="Image" />}
                                                            </figure>
                                                            }
                                                            
                                                            {item.title=== "Voluntary Certification For MPP" ? (
                                                                <div className={Styles.AsPdf}>
                                                                    <Container className={Styles.organicSpace}>
                                                                        <Row>
                                                                            <Col md={3} className={Styles.flexCenter}>
                                                                                <h3>Our Clients</h3>
                                                                            </Col>
                                                                            <Col md={9}>
                                                                                <div className={Styles.AsPDFContainer}>
                                                                                    {vcsmppData &&
                                                                                        vcsmppData.map((item)=>{
                                                                                            return(
                                                                                                <div className={Styles.box} key={item.order}>
                                                                                                    <button type="button" onClick={() => downloadXLSFile(constants.Image_BASE_URL + item.image, item.name)}>
                                                                                                        <figure>
                                                                                                            <img src={require('../../../assest/images/xlxs_icon.png')} className={Styles.xlxsIcon} alt={item.name} />
                                                                                                        </figure>
                                                                                                        <h6>{item.name}</h6>
                                                                                                    </button>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                </div> ) : null
                                                            }
                                                        </div>
                                                    </TabPanel>
                                                );
                                            })
                                        }
                                    </div>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
          
            {productData.fields && productData.fields.length > 0 &&
            <>
                {/* <div className={Styles.ayushProduct}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.ayushContainer}>
                                    <div className={Styles.centerAlight}>
                                        <h3>{productData.fields[0].title}</h3>
                                    </div>
                                    <div className={Styles.aboutPro}>
                                        {parse(`${productData.fields[0].description}`)}
                                    </div>
                                    <div className={Styles.centerAlight}>
                                        <figure className={Styles.CertificatImg}>
                                           {productData.fields[0].image[0] && <img src={constants.Image_BASE_URL+""+productData.fields[0].image[0].fileName} />}</figure>
                                    </div>
                                    <div className={Styles.ayushMask}>
                                        <h5 className={Styles.greenColor}><span>1</span> AYUSH STANDARD MARK</h5>
                                        <h5>Compliance to Domestic requirements or Schedule T compliance</h5>
                                        <h5 className={Styles.yellowColor}><span>2</span> AYUSH PREMIUM MARK</h5>
                                        <h5>Compliance to the GMP Requirements based on WHO Guidelines</h5>
                                        <table>
                                            <tr>
                                                <th>AYUSH STANDARD MARK</th>
                                                <th>AYUSH PREMIUM MARK</th>
                                            </tr>
                                            <tr>
                                                <td>For Domestic Market</td>
                                                <td>For International Market</td>
                                            </tr>
                                            <tr>
                                                <td>Assessed for   Domestic regulatory requirements</td>
                                                <td>Compliance to the domestic regulation and having in house testing facilities is a   prerequisite.</td>
                                            </tr>
                                            <tr>
                                                <td>Domestic regulation – Schedule T compliance</td>
                                                <td>WHO GMP compliance</td>
                                            </tr>
                                            <tr>
                                                <td>Surveillance evaluation: once in a year Certificate validity: 3 years</td>
                                                <td>Surveillance evaluation: once in a year Certificate validity: 3 years</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Benefits
                                                    <ul>
                                                        <li>Quality Mark</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    Benefits
                                                    <ul>
                                                        <li>Quality Mark</li>
                                                        <li>Accepted/Mandatory for Govt. supply</li>
                                                        <li>Provides basis for obtaining CoPP for export</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                <div className={Styles.ayushBenefit}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.ayushContainer}>
                                    <h5>{productData.fields[1].title}</h5>
                                    {parse(`${productData.fields[1].description}`)}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
            }
        </div>
    )
}

export default AyushCertification;