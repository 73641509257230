import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { getMissionAPI, getTataValueAPI, getMissionVideoAPI } from '../../services/routes.services';
import parse from 'html-react-parser';
import constants from '../../services/constants';
import Loader from "../Common/PageLoader/LoadingSpinner";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
const MissionVisionValue = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [missionContent, setMissionContent] = useState([]);
    const [valueContent, setValueContent] = useState([]);
    const [videoLink, setVodeoLink] = useState([]);
    const [videoShow, setVideoShow] = useState(false)
    const [loading, setLoading] = useState(true);
    const VideoHandel= ()=>{
        setVideoShow(true)
    }
    useEffect(()=>{
        getMissionAPI().then((res)=>{
            setMissionContent(res.data.mission)
            setLoading(false);
        })
        getTataValueAPI('Mission').then((res)=>{
            setValueContent(res.data.values)
            setLoading(false);
        })
        getMissionVideoAPI().then((res)=>{
            setVodeoLink(res.data.video[0])
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [])
    if (loading) {
        return <Loader />;
    }    
    return(
        <div className={'missionPage ' + Styles.missionPageBlock}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
             {BannerImage &&   <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.visionSec}>
                {missionContent &&
                    missionContent.map((item)=>{
                        return(
                            <div className={Styles.vissionElem} key={item.order}>
                                <div className={Styles.elemContent}>
                                 {item.icon &&   <img src={constants.Image_BASE_URL+""+item.icon} />}
                                    <div className={Styles.box}>
                                        <h4>{item.title}</h4>
                                        <p>{parse(`${item.description}`)}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={Styles.aboutValue}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.valueContainer}>
                                <div className={Styles.valueElement}>
                                    <h4>Tata Values</h4>
                                    <div className={Styles.valyeBoxContainer}>
                                        {valueContent &&
                                            valueContent.map((item)=>{
                                                return(
                                                    <div className={Styles.valueBox} key={item.order}>
                                                        <div className={Styles.heading}>
                                                            <h6>{item.title}</h6>
                                                          {item.image && <img src={constants.Image_BASE_URL+""+item.image} />}
                                                        </div>
                                                        <p>{parse(`${item.description}`)}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default MissionVisionValue;