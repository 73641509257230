import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Styles from '../../Style/Common.module.css';
import { Link, useLocation } from "react-router-dom";
import { getHeader } from "../../../services/routes.services";
import { getAssuranceOviewViewAPI } from '../../../services/routes.services';


const Breadcrumb=()=>{
    const [menu, setMenu] = useState([]);
    const location = useLocation();
    useEffect(() => {
        getHeader().then((res) => {
            setMenu(res.data.headers);
        });
    }, []);
    if (!menu || menu.length === 0) {
        return null;
    }

    const sortedMenu = [...menu].sort((a, b) => a.orderNumber - b.orderNumber);
    const allSubMenu = sortedMenu[1].children[1].children
    const allSubMenu1 = [...allSubMenu].sort((a, b) => a.orderNumber - b.orderNumber);
   
        return(
        <div className={'breadCrmStyle ' + Styles.IDList}>
            <Container>
                <Row>
                    <Col>
                    <ul>
                            {allSubMenu1.map((item) => {
                                const isActive = location.pathname === item.linkUrl;
                                return (
                                    <li key={item.orderNumber}>
                                        <Link
                                            to={item.linkUrl}
                                            title={item.linkText}
                                            className={isActive ? 'active' : ""}
                                        >
                                            {item.shorttitle || item.linkText}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Breadcrumb;