import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../../Style/Common.module.css';
import { Link, useLocation, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import constants from "../../../services/constants";
import { getKeyProjectsDetailAPI, getKeyProjectsAPI } from "../../../services/routes.services";
import Loader from "../../Common/PageLoader/LoadingSpinner";

const KeyProjectDetail = () => {
    const [keyData, setKeyData] = useState([]);
    const [subMenu, setSubMenu] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const location = useLocation();  // Get the current location

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getKeyProjectsDetailAPI(id),
            getKeyProjectsAPI(),
        ])
            .then(([keyDataRes, menuRes]) => {
                setKeyData(keyDataRes.data.keyproject[0]);
                setSubMenu(menuRes.data.keyproject);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return <Loader />;
    }
    const generateShortForm = (title) => {
        return title.split(' ')[0];
        // return title;
      };
    const currentBasePath = location.pathname.split('/').slice(0, -1).join('/');  // Get the base path

    return (
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
                <img src={require('../../../assest/images/key-project-banner.jpg')} className={Styles.innerBannerimg} alt={keyData.title} />
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{keyData.title}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={'breadCrmStyle ' + Styles.IDList}>
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                {subMenu.map((item) => {
                                    const isActive = id === item.link;
                                    const fullLink = `${currentBasePath}/${item.link}`;
                                    return (
                                        <li key={item.orderNumber}>
                                            <Link
                                                to={fullLink}
                                                title={item.title}
                                                className={isActive ? 'active' : ""}
                                            >
                                               {generateShortForm(item.title)} 
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.TQValue}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.TQvalueContainer}>
                                <Row>
                                    <Col md={8}>
                                        <div className={Styles.TQElem}>
                                            <h3>{keyData.title}</h3>
                                            {parse(`${keyData.description}`)}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={Styles.TQvalueImg}>
                                            <figure className={Styles.imgBgStyle}>
                                              {keyData.image &&  <img src={constants.Image_BASE_URL + keyData.image} alt={keyData.title} />}
                                            </figure>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default KeyProjectDetail;
