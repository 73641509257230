import React, { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import parse from 'html-react-parser';
import constants from '../../services/constants';

const LeaderShipSection = (props)=>{
    const teamData = props.leaderShip
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return(
        <div className={'leaderShip ' + Styles.TQTeam}>
            <Container>
                <Row>
                    <Col>
                        <div className={Styles.InnerContainer}>
                            <h3>Leadership Team</h3>
                            <div className={'leaderSliderContainer ' + Styles.LeaderSlider}>
                                <Swiper
                                    navigation={true}
                                    spaceBetween={0}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="leaderSwiper"
                                >
                                    {teamData &&
                                        teamData.map((item)=>{
                                            return(
                                                <SwiperSlide>
                                                    <div className={Styles.leaderItem} key={item._id}>
                                                        <div className={Styles.itemContent}>
                                                            <h3>{item.name}</h3>
                                                            <h4>{item.position}</h4>
                                                            <p>{parse(`${item.description}`)}</p>
                                                        </div>
                                                        <figure>
                                                           {item.image && <img src={ constants.Image_BASE_URL+""+item.image} />}
                                                        </figure>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    slidesPerView={4}
                                    spaceBetween={10}
                                    freeMode={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className={'leaderSwiperThum'}
                                >
                                    {teamData &&
                                        teamData.map((item)=>{
                                            return(
                                                <SwiperSlide>
                                                    <div className={Styles.leaderThum} key={item._id}>
                                                        <figure>
                                                          {item.image &&  <img src={ constants.Image_BASE_URL+""+item.image} />}
                                                        </figure>
                                                        <h6>{item.name} <span>{item.position}</span></h6>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LeaderShipSection;