import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link } from "react-router-dom";
import constants from '../../services/constants';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Banner = (props)=>{
    const assuranceResponsive = {
        superLargeDesktop: {
          breakpoint: { max: 3000, min: 0 },
          shouldResetAutoplay:true,
          items: 1,
        },
      };
    const bannerData = props.Slider
   
    return(
        <div className={'homeHeroBanner ' + Styles.HomeBanner}>
           {bannerData && <Carousel autoPlaySpeed={4000} transitionDuration={500} responsive={assuranceResponsive} autoPlay={true} infinite={true} arrows={false} showDots={true} containerClass="assuranceOwl dotsStyle">
                {bannerData.map((item, index)=>{
                    return(
                        <div key={index} className={Styles.BannerSlide}>
                           {item.bannerImage && <img className={Styles.heroImg} src={constants.Image_BASE_URL+""+item.bannerImage} alt={item.title} width='1517' height='621' />}
                            <div className={Styles.heroBannerContent}>
                                <h1>{item.title}</h1>
                                <Link to={item.link} className={BtnStyle.btn + ' ' + BtnStyle.BlurBtn} title="Explore">Explore Our Services</Link>
                            </div>
                            <div className={Styles.banerMask}>
                                <img src={require('../../assest/images/banner_mask.png')}/>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
}
        </div>
    )
} 

export default Banner;