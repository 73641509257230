
import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getKeyProjectsAPI,getKeyProjectsMain } from "../../services/routes.services";
import parse from 'html-react-parser';
import constants from '../../services/constants';
import Loader from "../Common/PageLoader/LoadingSpinner";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
import Breadcrumb from "../TQServices/ProjectServices/BreadCrumb";
const KeyProjects = ()=>{
    //math Path
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.image : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.linkText : ''; 

    const [keyData, setKeyData] = useState([]);
    const [keyMainData, setKeyMainData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        Promise.all([
            getKeyProjectsAPI(),
            getKeyProjectsMain()
        ])
        .then(([keyProjectRes, keyMainRes]) => {
            setKeyData(keyProjectRes.data.keyproject);
            setKeyMainData(keyMainRes.data.keyheading[0]);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading || keyData.length ===0) {
        return <Loader />;
    }
    //console.log(keyData)
    const keyDataOrder = keyData.sort((a, b) => a.order - b.order);
    //console.log(ProjectData)
    return(
        <div className={'keyPage ' + Styles.keyProjectPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner}>
              {BannerImage &&  <img src={constants.Image_BASE_URL+""+BannerImage} className={Styles.innerBannerimg} />}
                <div className={Styles.innerBannerContent}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.detail}>
                                    <h2>{BannerTitle}</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Breadcrumb />
            <div className={Styles.keyOption}>
                <Container>
                    <Row>
                        <Col md={5}>
                            <div className={Styles.keyHeading}>
                                <h1>Key <span>Projects</span> </h1>
                                {/* <p>{parse(`${keyMainData.description}`)}</p> */}
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className={Styles.keyPageContainer}>
                                {keyDataOrder && 
                                    keyDataOrder.map((item)=>{
                                        return(
                                            <div className={Styles.keyPageElem} key={item._id}>
                                                <h4>{item.title}</h4>
                                                <figure>
                                                  {item.innerimage &&  <img src={constants.Image_BASE_URL+""+item.innerimage} />}
                                                </figure>
                                                <div className={Styles.keyPageElemDEtail}>
                                                    <span>{item.order}</span>
                                                    <p>{parse(`${item.description}`)}</p>
                                                    
                                                    {/* <Link to={item.link} className={BtnStyle.roundBtn + ' ' + BtnStyle.RoundRed}><img src={require('../../assest/images/White_Arrow.png')} /></Link> */}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default KeyProjects;