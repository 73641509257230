import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css';
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import constants from '../../services/constants';
import { getBlogsAPI } from '../../services/routes.services';
import Loader from "../Common/PageLoader/LoadingSpinner";

const Blogs = () => {
    const [blogList, setBlogList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6); // Number of items per page
    const navigate = useNavigate();
    const BlogDetails=(item)=>{
        navigate(`/blog-detail/${item.link}`, 
            {state:{
                Id:item._id,
                Title: item.title, 
                Description: item.description,
                AboutAuthor: item.description1,
                BlogTime: item.blogdate,
                Authorpost: item.authorpost,
                Author: item.author
            }})
    }
    useEffect(() => {
        getBlogsAPI().then((res) => {
            setBlogList(res.data.blog);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
        return formattedDate;
    };

    // Calculate the blogs to display based on the current page
    const indexOfLastBlog = currentPage * itemsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
    const currentBlogs = blogList.slice(indexOfFirstBlog, indexOfLastBlog);

    // Calculate the total number of pages
    const totalPages = Math.ceil(blogList.length / itemsPerPage);

    // Pagination handler
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return <Loader />;
    }
 
    console.log(blogList)
    return (
        <div className={'directorsPage ' + Styles.boardPage}>
            <div className={Styles.innerBanner + ' ' + Styles.InnerSmallBanner + ' ' + Styles.noneMask}>
                <img src={require('../../assest/images/blog-banner.jpg')} className={Styles.innerBannerimg} />
                <div className={Styles.innerBannerContent}>
                    
                </div>
            </div>
            <div className={Styles.blogList}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.blogContainer}>
                                <Row>
                                    {currentBlogs && 
                                        currentBlogs.map((item) => (
                                            <Col md={4} key={item._id}>
                                                <div className={Styles.blogElem}>
                                                    <button type='button' onClick={()=> {BlogDetails(item)}}>
                                                        <figure>
                                                          {item.image && <img src={constants.Image_BASE_URL + "" + item.image} alt={item.title} />}
                                                        </figure>
                                                        <div className={Styles.blogContent}>
                                                            <h6>{item.title}</h6>
                                                            <ul>
                                                                <li>{formatDate(item.blogdate)}</li>
                                                                <li>/</li>
                                                                <li>{item.author}, {item.authorpost}</li>
                                                            </ul>
                                                        </div>
                                                    </button>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <ul className={Styles.paginaion}>
                                            {Array.from({ length: totalPages }, (_, index) => (
                                                <li
                                                    key={index + 1}
                                                    className={currentPage === index + 1 ? Styles.active : ''}
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Blogs;
